import React, { useState, useEffect, useRef } from 'react';
import { ImTruck } from "react-icons/im";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y, EffectFade, EffectCube, Virtual } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

export default function Gallery() {
  const [iconVisible, setIconVisible] = useState(false);
  const galleryRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      if (galleryRef.current) {
        const { top } = galleryRef.current.getBoundingClientRect();
        const windowHeight = window.innerHeight;

        if (top < windowHeight && top > 0) {
          setIconVisible(true);
        } else {
          setIconVisible(false);
        }
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <div id="galeria" ref={galleryRef} className="pb-10 playfair-display">
      <div className="w-full h-auto px-5 flex flex-col justify-center items-center pb-10">
        <div className="flex flex-row gap-2 items-center text-white my-20 text-[36px] md:text-[48px]">
          <span className={`icon ${iconVisible ? 'animate-icon' : ''}`}>
            <ImTruck className="text-red-600" size={56} />
          </span>
          <h2 className="font-bold text-3xl md:text-4xl ml-2 text-center text-white shadow-md transition-transform duration-300 hover:scale-105 font-raleway">
 GALERIA
</h2>
        </div>
      </div>
      <Swiper
        className="w-full xl:w-3/4 flex flex-row justify-center items-center py-10"
        modules={[Navigation, Pagination, Scrollbar, A11y, EffectFade, EffectCube, Virtual]}
        slidesPerView={1}
        virtual
        autoplay={true}
        centeredSlidesBounds={true}
        grabCursor={true}
        scrollbar={{ draggable: true }}
        pagination={{ el: '.swiper-pagination', clickable: true }}
        navigation={{
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
          clickable: true,
        }}
        breakpoints={{
          640: { slidesPerView: 1, spaceBetween: 10 },
          768: { slidesPerView: 2, spaceBetween: 10 },
          1024: { slidesPerView: 3, spaceBetween: 20 },
          1280: { slidesPerView: 3, spaceBetween: 10 },
        }}
        onInit={(swiper) => {
          swiper.update();
        }}
      >
        {Array.from({ length: 14 }).map((_, index) => (
          <SwiperSlide key={index} className="flex justify-center items-center relative overflow-hidden ">
            <div className="h-[300px] w-full relative group">
              <img
                src={`/${index + 1}.jpg`}
                alt=""
                className="relative object-cover w-full h-full bg-white transition-all duration-300 ease-in-out group-hover:brightness-100"
              />
              <div className="absolute inset-0 bg-black opacity-30 transition-opacity duration-300 ease-in-out group-hover:opacity-0" />
            </div>
          </SwiperSlide>
        ))}
        <div className="slider-controler pt-20">
          <div className="swiper-button-prev slider-arrow">
            <ion-icon name="arrow-back-outline"></ion-icon>
          </div>
          <div className="swiper-button-next slider-arrow">
            <ion-icon name="arrow-forward-outline"></ion-icon>
          </div>
        </div>
        <div className="swiper-pagination"></div>
      </Swiper>

    </div>
  );
}
