import React, { useState, useEffect, useRef } from 'react';
import Card from './Card';
import { ImTruck } from "react-icons/im";
import { FaTools, FaUserTie, FaGlobe } from 'react-icons/fa';
import { Link } from 'react-router-dom'; // Importuj Link

export default function Why() {
  const [iconVisible, setIconVisible] = useState(false);
  const whyRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      if (whyRef.current) {
        const { top } = whyRef.current.getBoundingClientRect();
        const windowHeight = window.innerHeight;

        if (top < windowHeight && top > 0) {
          setIconVisible(true);
        } else {
          setIconVisible(false);
        }
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <div ref={whyRef} className="w-full h-auto flex flex-col justify-center items-center pb-20 playfair-display">
      <div className="flex-row gap-2 text-white my-20 text-4xl font-bold flex justify-center items-center ">
        <span className={`icon ${iconVisible ? 'animate-icon' : ''}`}>
          <ImTruck className="text-red-600" size={56} />
        </span>
        <h2 className="font-bold text-3xl md:text-4xl ml-2 text-center text-white shadow-md transition-transform duration-300 hover:scale-105 font-raleway">
 DLACZEGO MY?
</h2>
      </div>
      <div className="w-screen flex flex-col lg:flex-row flex-wrap justify-center items-center gap-6">
        <div className="min-h-fit flex flex-col my-4  w-full max-w-[400px] h-[350px] bg-[#1a1a1a] p-6 rounded-lg transition-transform transform hover:scale-105">
          <div className="flex justify-center items-center mb-4 ">
            <FaTools size={64} className="text-red-600" />
          </div>
          <h3 className="text-xl font-bold text-white text-center">Kompleksowość obsługi</h3>
          <p className="text-base text-gray-300 text-center">Nasze usługi są w pełni kompleksowe. Nasza firma ma wieloletnie doświadczenie w zakresie pełnionych usług. Jesteśmy zarówno elastyczni, jak i skuteczni w działaniach.</p>
        </div>
        <div className="min-h-fit flex flex-col m-0 w-full my-4 max-w-[400px] h-[350px] bg-[#1a1a1a] p-6 rounded-lg transition-transform transform hover:scale-105">
          <div className="flex justify-center items-center mb-4 my-4">
            <FaGlobe size={64} className="text-red-600" />
          </div>
          <h3 className="text-xl font-bold text-white text-center">Globalny zakres usług</h3>
          <p className="text-base text-gray-300 text-center">Świadczymy nasze usługi na najwyższym poziomie. Zapewniamy nie tylko wysoką jakość surowców, ale także usług. Dbamy o stały kontakt z klientem na każdym etapie współpracy.</p>
        </div>
        <div className="min-h-fit flex flex-col my-4 w-full max-w-[400px] h-[350px] bg-[#1a1a1a] p-6 rounded-lg transition-transform transform hover:scale-105">
          <div className="flex justify-center items-center mb-4">
            <FaUserTie size={64} className="text-red-600" />
          </div>
          <h3 className="text-xl font-bold text-white text-center">Profesjonalne wsparcie</h3>
          <p className="text-base text-gray-300 text-center">Zapewniamy profesjonalne wsparcie naszych wykwalifikowanych spedytorów. Całodobowy kontakt koordynacyjny oraz indywidualne podejście do każdego klienta.</p>
        </div>
      </div>
      <Link to="/o-nas" className="mt-6 bg-red-600 text-white py-2 px-4 rounded hover:bg-red-700 transition">Przeczytaj więcej o nas</Link>
    </div>
  );
}
