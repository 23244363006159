import React, { useState, useEffect, useRef } from 'react';
import { ImTruck } from "react-icons/im";

const partners = [
  { name: "ACP Global Forwarding Sp. z o.o", imgSrc: "/acp.png" },
  { name: "Beghelli Polska Sp. z o.o", imgSrc: "/beghelli.png" },
  { name: "BMZ Poland Sp. z o.o", imgSrc: "/ruckzuck.png" },
  { name: "Classen Polska S. A.", imgSrc: "/classen.png" },
  { name: "DB Cargo S.A", imgSrc: "/dbcargo.png" },
  { name: "Delis Sp. z o.o", imgSrc: "/delis.png" },
  { name: "DTA Logistic", imgSrc: "/dta.png" },
  { name: "Martex Sp. z o.o", imgSrc: "/martex.png" },
  { name: "Megalight Rybnik", imgSrc: "/megalight.png" },
  { name: "RC Pro", imgSrc: "/rcpro.png" },
  { name: "RuckZuck", imgSrc: "/bmz.png" },
  { name: "TPM Poland", imgSrc: "/tpm.png" },
  { name: "Uliarczyk Sp. z o.o.", imgSrc: "/uliarczyk.png" },
  { name: "Nosta Transport Gmbh", imgSrc: "/nosta.png" },
  { name: "FM Logistic", imgSrc: "/fmlog.png" },
];

const Oferta = () => {
  const [showMore, setShowMore] = useState(false);
  const [iconVisible, setIconVisible] = useState(false);
  const ofertaRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      if (ofertaRef.current) {
        const { top } = ofertaRef.current.getBoundingClientRect();
        const windowHeight = window.innerHeight;

        if (top < windowHeight && top > 0) {
          setIconVisible(true);
        } else {
          setIconVisible(false);
        }
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <div id="oferta" ref={ofertaRef} className="bg-[#222020] text-white py-10 px-5 playfair-display">
      <div className="flex items-center justify-center mb-10">
        <div className={`icon ${iconVisible ? 'animate-icon' : ''}`}>
          <ImTruck size={56} className="text-red-600 mx-2" />
        </div>
        <h2 className="font-bold text-3xl md:text-4xl ml-2 text-center text-white shadow-md transition-transform duration-300 hover:scale-105 font-raleway">
  OFERTA
</h2>
      </div>
      <p className="text-center mb-4 w-full md:w-3/4 mx-auto text-base md:text-lg">
        Obsługujemy wszystkie relacje krajowe oraz międzynarodowe na terenie Unii Europejskiej.
      </p>
      <p className="text-center mb-20 w-full md:w-3/4 mx-auto text-base md:text-lg">
        Od początku naszej działalności udało się nam zaistnieć na rynku i zdobyć zaufanie szerokiej grupy klientów, oferując kompleksowe usługi spedycyjne i stale je dostosowując do potrzeb i wymagań naszych klientów.
      </p>

      <div className="max-w-6xl mx-auto grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-5">
        {partners.slice(0, showMore ? partners.length : 4).map((partner, index) => (
          <div key={index} className="bg-[#252222] rounded-lg overflow-hidden transition-transform duration-300 hover:scale-105 hover:shadow-lg hover:shadow-red-600">
            <div className="flex justify-center items-center h-32">
              <img src={partner.imgSrc} alt={partner.name} className="max-h-full object-contain" />
            </div>
            <div className="p-3">
              <h3 className="font-bold text-center text-sm md:text-base">{partner.name}</h3>
            </div>
          </div>
        ))}
      </div>

      {!showMore && (
        <div className="text-center mt-5">
          <button
            onClick={() => setShowMore(true)}
            className="bg-red-600 text-white px-4 py-2 rounded hover:bg-red-700 transition">
            Zobacz więcej
          </button>
        </div>
      )}
    </div>
  );
};

export default Oferta;
