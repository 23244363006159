import React, { useState } from 'react';
import Hamburger from 'hamburger-react';
import { Link, useNavigate } from 'react-router-dom';
import { FaSquareFacebook } from "react-icons/fa6";

export default function Nav() {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const [visibleItems, setVisibleItems] = useState([]);

  const handleToggle = () => {
    setIsOpen(!isOpen);
    if (!isOpen) {
      setVisibleItems([]);
      setTimeout(() => {
        ["O nas", "Oferta", "Kontakt", "Facebook"].forEach((item, index) => {
          setTimeout(() => {
            setVisibleItems((prev) => [...prev, item]);
          }, index * 100);
        });
      }, 100);
    } else {
      setVisibleItems([]);
    }
  };

  const handleItemClick = (path, targetId) => {
    navigate(path, { state: { targetId } });
    setIsOpen(false);
    setVisibleItems([]);

    if (targetId) {
      setTimeout(() => {
        const el = document.getElementById(targetId);
        if (el) {
          el.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
      }, 100); // Delay to allow for the menu to close before scrolling
    }
  };

  return (
    <nav className="top-0 bg-black h-16 w-full fixed z-50 shadow-lg playfair-display">
      <div className="flex justify-between items-center h-full px-5 lg:px-20">
        {/* Logo */}
        <div className="w-48">
          <Link to="/" onClick={() => {
            window.scrollTo({ top: 0, behavior: 'smooth' });
            navigate('/');
          }}>
            <img src="/logo.png" alt="Ltl-express logo" className="object-fill" />
          </Link>
        </div>

        {/* Menu for larger screens */}
        <div className="hidden lg:flex items-center space-x-8 bg-transparent">
          {["O nas", "Oferta", "Kontakt"].map((item) => (
            <span
              key={item}
              className="relative text-white uppercase py-2 transition duration-300 ease-in-out cursor-pointer text-lg font-bold group"
              onClick={() => {
                if (item === "O nas") {
                  handleItemClick("/o-nas", "onas");
                } else {
                  handleItemClick("/", item.toLowerCase());
                }
              }}
            >
              {item}
              <span className="absolute left-0 bottom-0 h-1 bg-gradient-to-r from-red-500 to-red-700 w-0 transition-all duration-300 ease-in-out group-hover:w-full"></span>
            </span>
          ))}
          <span
            className="relative text-red-500 uppercase py-2 transition duration-300 ease-in-out cursor-pointer text-lg font-bold group"
            onClick={() => window.open("https://www.facebook.com/ltlexpresstransport", "_blank")}
          >
            <FaSquareFacebook size={30} className="text-red-500 group-hover:text-red-700 transition duration-300" />
          </span>
        </div>

        {/* Hamburger menu for smaller screens */}
        <div className="lg:hidden">
          <Hamburger
            color="white"
            toggled={isOpen}
            toggle={handleToggle}
          />
        </div>
      </div>

      {/* Hamburger menu items */}
      <div className={`transition-all duration-300 ease-in-out ${isOpen ? 'bg-transparent max-h-screen opacity-100' : 'max-h-0 opacity-0 overflow-hidden'}`}>
        <ul className="flex flex-col items-center pb-4 w-full mx-0 px-0">
          {["O nas", "Oferta", "Kontakt"].map((item, index) => (
            <li
              key={item}
              onClick={() => {
                if (item === "O nas") {
                  handleItemClick("/o-nas", "onas");
                } else {
                  handleItemClick("/", item.toLowerCase());
                }
              }}
              className={`text-white font-bold uppercase transition-opacity duration-200 ease-in-out cursor-pointer`}
              style={{
                opacity: visibleItems.includes(item) ? 1 : 0,
                transitionDelay: `${(index + 1) * 300}ms`,
                width: '100%',
                textAlign: 'center',
                padding: '1.5rem 0',
                backgroundColor: isOpen && visibleItems.includes(item) ? 'rgba(255, 0, 0, 0.8)' : 'transparent',
              }}
            >
              {item}
            </li>
          ))}

          {/* Facebook Icon */}
          <li
            onClick={() => window.open("https://www.facebook.com/ltlexpresstransport", "_blank")}
            className={`transition-opacity duration-200 ease-in-out cursor-pointer`}
            style={{
              opacity: visibleItems.includes("Facebook") ? 1 : 0,
              transitionDelay: `${(4) * 300}ms`,
              width: '100%',
              textAlign: 'center',
              padding: '1.5rem 0',
              backgroundColor: isOpen ? 'rgba(255, 0, 0, 0.8)' : 'transparent',
            }}
          >
            <FaSquareFacebook size={30} className="text-black mx-auto hover:scale-110 transition duration-300" />
          </li>
        </ul>
      </div>
    </nav>
  );
}
